import OrgClientStatuses from './orgClientStatuses';

export const BrandAccessStatuses = {
  HAS_SHOP: 'HAS_SHOP',
  HAS_SHOPLEARN: 'HAS_SHOPLEARN',
  HAS_LEARN: 'HAS_LEARN',
  UNDER_REVIEW: 'UNDER_REVIEW', // user has a pending affiliation
  NO_ACCESS: 'NO_ACCESS', // user has none of the above
  COMING_SOON: 'COMING_SOON', // OrgClientStatuses.COMING_SOON
  DEPARTED: 'DEPARTED',
  INACTIVE: 'INACTIVE', //  OrgClientStatuses.DEPARTED/PROSPECTIVE
  BLOCKED: 'BLOCKED',
};

export const BrandAccessDefaultLabels = {
  HAS_SHOP: 'Qualified for rewards',
  HAS_LEARN: 'Insider access',
  UNDER_REVIEW: 'Qualifications under review', // user has a pending affiliation
  NO_ACCESS: 'Not qualified', // user has none of the above
  COMING_SOON: 'Rewards coming soon', // OrgClientStatuses.COMING_SOON
  INACTIVE: 'No rewards available', //  OrgClientStatuses.DEPARTED/PROSPECTIVE
  BLOCKED: 'Blocked brand',
};

export const BrandAccessDefaultHeadlines = {
  HAS_SHOP: 'You\'ve qualified for rewards',
  HAS_LEARN: 'You\'ve qualified for insider access',
  UNDER_REVIEW: 'Your qualifications are under review', // user has a pending affiliation
  NO_ACCESS: undefined,
  COMING_SOON: 'Rewards on the way', // OrgClientStatuses.COMING_SOON
  INACTIVE: 'No rewards available', //  OrgClientStatuses.DEPARTED/PROSPECTIVE
  BLOCKED: 'You’ve blocked this brand',
};

export const BrandAccessDefaultExplainers = {
  HAS_SHOP: 'Your verified affiliation(s) qualifies you for exclusive rewards from {orgName}.',
  HAS_LEARN: 'Your verified affiliation(s) qualifies you for insider access from {orgName}.',
  UNDER_REVIEW: 'Your verification with {teamName} is under review. If approved, you may qualify for {orgName} rewards.',
  NO_ACCESS: undefined,
  COMING_SOON: 'Rewards for {orgName} will soon be available on ExpertVoice.', // OrgClientStatuses.COMING_SOON
  INACTIVE: 'Currently, this brand does not offer any rewards to experts.', //  OrgClientStatuses.DEPARTED/PROSPECTIVE
  BLOCKED: 'Blocking brands prevents them from offering you access to their programs and deals.',
};

export const getBrandAccessStatus = (
  { hasShop, hasLearn, shop, learn },
  orgClientStatus,
  hasPending,
  blocked,
) => {
  const status = parseInt(orgClientStatus, 10);
  if (blocked) {
    return BrandAccessStatuses.BLOCKED;
  }
  if (hasShop || shop) {
    if (hasLearn || learn) {
      return BrandAccessStatuses.HAS_SHOPLEARN;
    }
    return BrandAccessStatuses.HAS_SHOP;
  }
  if (hasLearn || learn) {
    return BrandAccessStatuses.HAS_LEARN;
  }
  if (status === OrgClientStatuses.COMING_SOON) {
    return BrandAccessStatuses.COMING_SOON;
  }
  if (status === OrgClientStatuses.DEPARTED
    || status === OrgClientStatuses.PROSPECTIVE
    || status === OrgClientStatuses.INACTIVE
    || !status) {
    return BrandAccessStatuses.INACTIVE;
  }
  if (hasPending) {
    return BrandAccessStatuses.UNDER_REVIEW;
  }
  return BrandAccessStatuses.NO_ACCESS;
};
