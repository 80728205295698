import { useEffect, useRef } from 'react';

/**
 * Utility for generating a working event queue to be processed using the bulk AC sender
 *
 * @param interval MS between batch sends
 * @param sendEvent Analytics Send Event - expects to know how to handle data arrays
 * @returns {(function(*, *): void)|*}
 */
// eslint-disable-next-line import/prefer-default-export
export const useBulkAnalytics = ({
  interval = 500,
  sendEvent,
}) => {
  const queue = useRef({});
  useEffect(() => {
    const flush = () => {
      // Copy over the events and reset the working queue
      const events = { ...queue.current };
      queue.current = {};

      Object.keys(events)
        .forEach((action) => sendEvent(action, events[action]));
    };

    const th = window.setInterval(flush, interval);
    return () => {
      if (th) window.clearInterval(th);
      flush();
    };
  }, [sendEvent, interval]);

  return (action, data) => {
    queue.current[action] = queue.current[action] || [];
    queue.current[action].push(data);
  };
};
