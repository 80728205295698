import React, { Suspense, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { usePreviewMode } from 'ERC/previewMode/PreviewMode';

import { PageTypesByType } from './PageTypes';
import { registerAnalyticsDecorator, removeDecorator, pageView } from '../utils/analytics';
import { loadUser } from './redux/actions';
import CommunityPreview from './components/preview/preview';
import InteractionKiller from '../common/InteractionKiller';

import './communityPage.less';

const BrandPage = React.lazy(() => import(/* webpackChunkName: "community-brand" */ './components/type/brandPage'));
const DefaultPage = React.lazy(() => import(/* webpackChunkName: "community-default" */ './components/type/defaultPage'));
const ProgramPage = React.lazy(() => import(/* webpackChunkName: "community-program" */ './components/type/programPage'));
const TeamPage = React.lazy(() => import(/* webpackChunkName: "community-team" */ './components/type/teamPage'));
const TeamClassPage = React.lazy(() => import('./components/type/teamClassPage'));
/**
 * Main Community Page Component
 */
const CommunityPage = () => {
  const dispatch = useDispatch();
  usePreviewMode();

  const {
    isAuthenticated,
    membershipStatus,
    membershipType,
    name,
    orgId,
    pageId,
    pageKey,
    pageType,
    preview,
    teamId,
    uuid,
  } = useSelector((state) => state.community);

  useEffect(() => {
    // Register a global AC decorator to fill in the general page data
    const decorator = (data = {}) => ({
      ...(data || {}),
      authenticated: isAuthenticated,
      mfgId: data.mfgId || orgId || undefined,
      pageId,
      pageKey,
      pageType,
      pageName: name,
      teamId: teamId || undefined,
    });
    registerAnalyticsDecorator(decorator);

    // Send a page view event
    pageView();

    return () => {
      removeDecorator(decorator);
    };
  }, [isAuthenticated, pageId, pageKey, pageType, name, orgId, teamId]);

  // Ensure the authenticated user is loaded
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadUser(uuid));
    }
  }, [dispatch, isAuthenticated, uuid]);

  const renderPage = () => {
    switch (pageType) {
      case PageTypesByType.MANUFACTURER.type:
        // unauthenticated brand page should render the default acquisition page
        return isAuthenticated ? (
          <BrandPage />
        ) : (
          <DefaultPage />
        );
      case PageTypesByType.PROGRAM.type:
        return (
          <ProgramPage />
        );
      case PageTypesByType.TEAM_CLASS.type:
        return (
          <TeamClassPage />
        );
      case PageTypesByType.GROUP.type:
      case PageTypesByType.RETAILER.type:
        return isAuthenticated && !preview ? (
          <TeamPage />
        ) : (
          <DefaultPage />
        );
      default:
        return isAuthenticated && !preview ? (
          <Redirect to="/home" />
        ) : (
          <DefaultPage />
        );
    }
  };

  return (
    <>
      {preview ? (
        <CommunityPreview />
      ) : null}

      <section
        className="contain-community"
        data-membershipstatus={membershipStatus}
        data-membershiptype={membershipType}
        data-pagetype={pageType}
        data-preview={preview}
      >
        {preview ? (
          <InteractionKiller
            allowed={{
              '.accordion, .tab': ['click'], // Allow accordion and tab clicks
            }}
            events={['click', 'change', 'keydown', 'submit']}
          />
        ) : null}

        <Suspense fallback={<div />}>
          {renderPage()}
        </Suspense>
      </section>
    </>
  );
};

export default CommunityPage;
